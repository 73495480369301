



















































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission,
} from "@/data/models/Questionnaires";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm,
  },
})
export default class AddHouseholdMemberReferralForm extends Vue {
  @Prop()
  public memberId!: string;

  @Prop()
  public id!: string;
  public provider!: DataProvider<any>;
  private userProvider: DataProvider<any> | null = null;
  private user: any = null;
  private item: any = null;
  private questionnaire: any = null;
  private answers: any[] | null = null;
  created() {
    this.provider = this.$service.providers.actionActionTypes;
    this.userProvider = this.$service.providers.users;
  }
  async mounted() {
    this.item = {
      householdMemberId: this.memberId,
      projectId: null,
      date: new Date().toISOString().split("T")[0],
      userGroupId: null,
      userGroup: null,
      userId: null,
      user: null,
      comments: null,
    };
  }
}
