export default [
  {
    name: "UserGroup/Name",
    required: true,
    label: "Referral Type",
    align: "left",
    sortable: true,
    field: (i: any) => i.userGroup.name
  },
  {
    name: "User/FirstName",
    required: true,
    label: "Name of Referral",
    align: "left",
    sortable: true,
    field: (i: any) => (i ? `${i.user.firstName} ${i.user.lastName}` : "")
  },
  {
    name: "Date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => (i.date ? new Date(Date.parse(i.date)) : undefined),
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "Project/Title",
    required: true,
    label: "Project",
    align: "left",
    sortable: true,
    field: (i: any) => (i.project ? i.project.title : "")
  }
];
