var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-container',_vm._g(_vm._b({attrs:{"title":"Referral","provider":_vm.provider,"crud":_vm.$service.crud.referrals,"item":_vm.item,"id":_vm.id},on:{"update:item":function($event){_vm.item=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners),[(_vm.item)?_c('div',{},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"filled":"","provider":_vm.$service.providers.userGroups,"label":"Referral Type","value":_vm.item.userGroupId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ("" + (i.name)) : ''); },"rules":[_vm.$rules.required]},on:{"update:value":function($event){return _vm.$set(_vm.item, "userGroupId", $event)},"input":function($event){_vm.item.user = null;
            _vm.item.userId = null;
            _vm.userProvider = _vm.$service.providers.userGroupUsers($event.id);}},model:{value:(_vm.item.userGroup),callback:function ($$v) {_vm.$set(_vm.item, "userGroup", $$v)},expression:"item.userGroup"}})],1),_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"disabled":!_vm.item.userGroupId,"filled":"","provider":_vm.userProvider,"label":"Name of Referral","value":_vm.item.userId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ((i.firstName) + " " + (i.lastName)) : ''); },"rules":[_vm.$rules.required]},on:{"update:value":function($event){return _vm.$set(_vm.item, "userId", $event)}},model:{value:(_vm.item.user),callback:function ($$v) {_vm.$set(_vm.item, "user", $$v)},expression:"item.user"}})],1),_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"filled":"","provider":_vm.$service.providers.intakeFormProjects,"label":"Project","value":_vm.item.projectId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ("" + (i.title)) : ''); },"oDataArgs":{
            columnFilters: [
              {
                column: 'Programme/Type',
                op: 'Equals',
                value: '\'Implementation\'',
              } ],
          }},on:{"update:value":function($event){return _vm.$set(_vm.item, "projectId", $event)}},model:{value:(_vm.item.project),callback:function ($$v) {_vm.$set(_vm.item, "project", $$v)},expression:"item.project"}})],1),_c('div',{staticClass:"col-12"},[_c('date-picker',{attrs:{"label":"Date","rules":[_vm.$rules.required]},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1),_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"label":"Comments","filled":"","type":"textarea"},model:{value:(_vm.item.comments),callback:function ($$v) {_vm.$set(_vm.item, "comments", $$v)},expression:"item.comments"}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }