
































































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import Columns from "@/data/columns/Referrals";
import AddHouseholdMemberReferralForm from "@/components/forms/AddHouseholdMemberReferralForm.vue";
import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberReferralForm
  }
})
export default class HouseholdMemberReferralsTable extends Vue {
  @Prop()
  public id!: string;
  private provider: DataProvider<any> | null = null;
  private columns = Columns;
  private addDialog = false;
  private selectedId = null;
  private member: any = null;
  @Watch("id", { immediate: true })
  onActionIdChanged() {
    if (!this.id) {
      return;
    }
    this.provider = this.$service.providers.householdMembersReferrals(this.id);
  }

  async mounted() {
    this.member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
  }

  async download() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract("Referrals/Extract", null, this.id),
      `${this.member.firstName}_${this.member.lastName}_Refferals_${utc}.xlsx`
    );
  }
}
